export const environment = {
  production: false,
  hasIam: false,
  apiPlaces:  'https://places.temrango.com.br',
  apiUsers:   'https://users.temrango.com.br',
  apiOrders:  'https://orders.temrango.com.br',
  apiDomains: 'https://domains.temrango.com.br',
  apiZero1BitIam: 'https://zero1bit-iam.temrango.com.br',
  apmServer:  'https://apm.temrango.com.br',
};
